import React from 'react';
import './index.css';
import LandingPage from './components/Landingpage';

function App() {
  return (
    <div>
      <LandingPage />
    </div>
  );
}

export default App;
